import { Colors } from "@glow/shared";
import React from "react";

/**
 * Sets the top bar color in Safari
 */
export const ThemeColorHeader = ({
  darkColor = Colors.DARK_PRIMARY_BG_COLOR,
  lightColor = Colors.PRIMARY_BG_COLOR,
}: {
  darkColor?: string;
  lightColor?: string;
}) => {
  return (
    <>
      <meta
        name="theme-color"
        content={lightColor}
        media="(prefers-color-scheme: light)"
      />
      <meta
        name="theme-color"
        content={darkColor}
        media="(prefers-color-scheme: dark)"
      />
    </>
  );
};
