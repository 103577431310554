import { NftInfo, NftCollection } from "@glow/shared";
import React from "react";

export const SearchContext = React.createContext<{
  showSearch: () => void;
  hideSearch: () => void;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}>(null as any);

export enum SearchItemType {
  NftCollection,
  Nft,
}

export type SearchItem =
  | {
      type: SearchItemType.NftCollection;
      collection: NftCollection.BasicInfo;
    }
  | { type: SearchItemType.Nft; nft: NftInfo };
