import { GlowIdTypes, Solana } from "@glow/shared";
import { useCallback, useEffect, useState } from "react";
import { LuxEvent, LuxEventTarget } from "@lux/atoms/components/LuxEventTarget";

const glowMap = new Map<string, GlowIdTypes.BasicInfo>();

type UpdateGlowIdInfoEvent = {
  type: "update-glow-id";
  address: Solana.Address;
};

const GlowIdEventTarget = new LuxEventTarget<UpdateGlowIdInfoEvent>();

export const addGlowIdDict = (params: any) => {
  if (!params || typeof params !== "object" || !("glow_id_dict" in params)) {
    return;
  }

  const { glow_id_dict } = params as GlowIdTypes.Dict;

  for (const [address, info] of Object.entries(glow_id_dict)) {
    glowMap.set(address, info);
    GlowIdEventTarget.dispatchEvent({ type: "update-glow-id", address });
  }
};

export const useGlowId = (
  address: Solana.Address | undefined | null,
): GlowIdTypes.BasicInfo | null => {
  const [glowId, setGlowId] = useState(() => resolveAddress(address));

  useEffect(() => {
    const handleEvent = (event: LuxEvent) => {
      if (event.type === "update-glow-id") {
        const updateGlowIdEvent = event as UpdateGlowIdInfoEvent;
        if (updateGlowIdEvent.address === address) {
          setGlowId(resolveAddress(updateGlowIdEvent.address));
        }
      }
    };

    if (!address) {
      // If there's no address to subscribed to, don't subscribe.
      return undefined;
    }

    GlowIdEventTarget.addEventListener("update-glow-id", handleEvent);

    setGlowId(resolveAddress(address));

    return () => {
      GlowIdEventTarget.removeEventListener("update-glow-id", handleEvent);
    };
  }, [setGlowId, address]);

  return glowId;
};

export const useGlowIdResolve = (): ((
  address: Solana.Address,
) => GlowIdTypes.BasicInfo | null) => {
  const resolve = useCallback((address: Solana.Address) => {
    return glowMap.get(address) ?? null;
  }, []);
  return resolve;
};

const resolveAddress = (address: Solana.Address | undefined | null) => {
  if (!address) {
    return null;
  }

  return glowMap.get(address) || null;
};
