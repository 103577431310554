import { getGlowImageUrl, Solana } from "@glow/shared";
import classNames from "classnames";
import React from "react";
import { MarketplaceIcon } from "./MarketplaceIcon";

export const SquareNftImage = ({
  imageUrl,
  name,
  size,
  className,
  marketplace,
  rounded,
}: {
  imageUrl: string | null | undefined;
  name: string | null;
  marketplace?: Solana.Marketplace | null;
  size: number;
  className?: string;
  rounded?: boolean;
}) => {
  const cdnImage = imageUrl
    ? getGlowImageUrl({
        url: imageUrl,
        width: size * 2,
        height: size * 2,
        fit: "crop",
      })
    : null;

  return (
    <div className={"relative"}>
      <div
        className={classNames("nft-image", className, { rounded })}
        style={{ width: size, height: size }}
      >
        {cdnImage ? (
          <div
            className="nft-image-inner"
            style={{
              backgroundImage: `url(${cdnImage})`,
              width: "100%",
              height: "100%",
            }}
            title={name || undefined}
          />
        ) : (
          <div className="placeholder" />
        )}
      </div>

      {marketplace && (
        <div className="marketplace">
          <MarketplaceIcon marketplace={marketplace} size={16} />
        </div>
      )}

      <style jsx>{`
        .nft-image {
          overflow: hidden;
          position: relative;

          .nft-image-inner {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
          }

          .placeholder {
            width: 100%;
            height: 100%;
            background-color: var(--tertiary-color);
          }
        }

        .marketplace {
          width: 20px;
          height: 20px;
          position: absolute;
          display: flex;
          right: -4px;
          bottom: -4px;
          border-radius: 100px;
          // TODO: update this to use an svg cutout instead
          border: 2px solid var(--secondary-bg-color);

          :global(svg) {
            width: 16px;
            height: 16px;
          }
        }
      `}</style>
    </div>
  );
};
