import { pluralize } from "@glow/shared";
import React from "react";

export const Count = ({ num, word }: { num: number; word?: string }) => {
  let str = (num ?? 0).toLocaleString();
  if (word) {
    str += ` ${pluralize(word, num)}`;
  }

  return <>{str}</>;
};
