import { Colors } from "@glow/shared";
import { LuxButton } from "@lux/atoms/components/LuxButton";
import GlowCoinIcon from "@glow/react/icons/GlowCoin.svg";
import SearchIcon from "@lux/icons/SearchThick16.svg";
import Link from "next/link";
import React, { useContext } from "react";

import { GlowButton } from "@components/atoms/GlowButton";
import { ThemeColorHeader } from "@components/atoms/ThemeColorHeader";
import { GlowConnectButton } from "@components/auth/GlowConnectButton";
import { useUserContext } from "@components/auth/UserContext";
import { SearchContext } from "@components/search/search-types";
import { ResponsiveBreakpoint } from "@utils/StyleConstants";

export const SolanaNav = () => {
  const { showSearch } = useContext(SearchContext);
  return (
    <nav>
      <ThemeColorHeader
        lightColor={Colors.SECONDARY_BG_COLOR}
        darkColor={Colors.DARK_SECONDARY_BG_COLOR}
      />

      <div className="flex-center spread">
        <div className="flex-center">
          <div className="logo flex-center">
            <Link href="/" legacyBehavior>
              <a className="flex-center">
                <GlowCoinIcon />
              </a>
            </Link>
          </div>

          <div className="search flex-center animated" onClick={showSearch}>
            <SearchIcon />
            <div className="label ml-2">Search</div>
          </div>
        </div>

        <div className="buttons">
          <ButtonSection />
        </div>
      </div>

      <style jsx>{`
        nav {
          position: sticky;
          top: 0;
          background-color: var(--primary-bg-color-translucent);
          backdrop-filter: blur(16px);
          padding: var(--nav-vertical-padding) var(--side-padding);
          z-index: 10;

          @supports (-moz-appearance: none) {
            background-color: var(--primary-bg-color);
          }

          .logo {
            margin-right: 1rem;

            a {
              color: var(--primary-color);

              &:hover {
                color: var(--brand-active-color);
              }
            }

            :global(svg) {
              width: 1.5rem;
              height: 1.5rem;
            }
          }

          .search {
            min-width: 300px;
            cursor: text;
            border-left: 1px solid var(--divider-color);
            padding-left: 1rem;
            color: var(--tertiary-color);

            .ml-2:after {
              content: " for NFTs or collections";
            }

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              min-width: auto;

              .label {
                display: none;
              }

              .ml-2:after {
                content: "";
              }
            }

            &:hover {
              color: var(--secondary-color);
            }
          }

          .buttons {
            margin: -0.5rem 0;
          }
        }
      `}</style>
    </nav>
  );
};

const ButtonSection = () => {
  const { canSignIn } = useUserContext();

  return (
    <div className="nav-buttons flex-center">
      <LuxButton
        label="Explore NFTs"
        size="small"
        href="/nfts"
        color="secondary"
        variant="link"
        className="nfts-button mr-3"
      />

      {canSignIn ? <GlowConnectButton /> : <DownloadButton />}

      <style jsx>{`
        @media (max-width: ${ResponsiveBreakpoint.tiny}) {
          .nav-buttons {
            :global(.nfts-button) {
              display: none;
            }
          }
        }
      `}</style>
    </div>
  );
};

const DownloadButton = () => {
  return (
    <div className="download-button">
      <div className="desktop-only">
        <GlowButton
          label="Download the App"
          size="small"
          rounded
          href="/download"
        />
      </div>
      <div className="mobile-only">
        <GlowButton label="Download" size="small" rounded href="/download" />
      </div>
    </div>
  );
};
